exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alle-opskrifter-js": () => import("./../../../src/pages/alle-opskrifter.js" /* webpackChunkName: "component---src-pages-alle-opskrifter-js" */),
  "component---src-pages-alle-opslag-js": () => import("./../../../src/pages/alle-opslag.js" /* webpackChunkName: "component---src-pages-alle-opslag-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-indeks-js": () => import("./../../../src/pages/indeks.js" /* webpackChunkName: "component---src-pages-indeks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredienser-js": () => import("./../../../src/pages/ingredienser.js" /* webpackChunkName: "component---src-pages-ingredienser-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-opskrifter-js": () => import("./../../../src/pages/opskrifter.js" /* webpackChunkName: "component---src-pages-opskrifter-js" */),
  "component---src-pages-soeg-js": () => import("./../../../src/pages/soeg.js" /* webpackChunkName: "component---src-pages-soeg-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-indeks-js": () => import("./../../../src/templates/indeks.js" /* webpackChunkName: "component---src-templates-indeks-js" */),
  "component---src-templates-ingredient-js": () => import("./../../../src/templates/ingredient.js" /* webpackChunkName: "component---src-templates-ingredient-js" */),
  "component---src-templates-ingredients-js": () => import("./../../../src/templates/ingredients.js" /* webpackChunkName: "component---src-templates-ingredients-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

